// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels"

window.global = window;
global.$ = require("jquery");

require("jquery");
require("jquery-ui");

Rails.start()
//Turbolinks.start()
//ActiveStorage.start()

window.bootstrap = require('js/services/bootstrap/dist/js/bootstrap.bundle.js');

require("js/services/jquery.magnific-popup");
//require("js/services/typed");

import '../js/social_network/fluidbook_without_bootstrap.js' 
import '../js/index/turnjs' 




//require("animejs/lib/anime.min");
//require("tiny-slider/dist/min/tiny-slider");


//require("jquery-ui/ui/jquery.ui.core");
//require("jquery-ui/ui/jquery.ui.widget");
//require("jquery-ui/ui/jquery.ui.mouse");
//require("jquery-ui/ui/jquery.ui.effect");
//require("jquery-ui/ui/jquery.ui.effect-shake";


$(document).ready(function () {
  const password_input = $(".password_input");
  let j = 0;
  for (j = 0; j < password_input.length; j++) {
    password_input[j].addEventListener("click", function () {
      var password = document.querySelector("." + $(this).attr("target_input"));
      var type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
  
      $(this).find(".password_icon").toggle();
    });
  }
})


//global.$ = require("jquery");
//
//
//require("js/welcome_2023.js");
//
//require("js/services/jquery.magnific-popup");
//
//import '../js/social_network/fluidbook.js' 
//import '../js/index/turnjs' 
//
//require("js/welcome_2023.js");


